import { AddressType } from '../types/localization/localization';

export const objectToFormdata = (body: any) => {
  console.dir(body)
  const formData = new FormData();
  Object.keys(body).forEach(key => {
    if (Array.isArray(body[key as keyof typeof body]) && body[key as keyof typeof body] !== null) {
      body[key as keyof typeof body].forEach((item: any) => {
        if (typeof item === 'object') {
          Object.keys(item).forEach(nestedKey =>
            formData.append(
              `${key}[][${nestedKey}]`,
              item[nestedKey as keyof typeof body],
            ),
          );
        } else formData.append(`${key}[]`, item);
      });
    } else if (
      typeof body[key as keyof typeof body] === 'object' &&
      !Array.isArray(body[key as keyof typeof body]) &&
      body[key as keyof typeof body] !== null &&
      body[key as keyof typeof body] instanceof File === false
    ) {
      Object.keys(body[key as keyof typeof body]).forEach(nestedKey =>
        formData.append(
          `${key}[${nestedKey}]`,
          body[key as keyof typeof body][nestedKey as keyof typeof body],
        ),
      );
    } else {
      formData.append(key, body[key as keyof typeof body]);
    }
  });
  return formData;
};

export const fuzzyContent = ({ place_name, context, text, place }: AddressType) =>
  context
    ? context.reduce(
        (
          acc: { [name: string]: string },
          val: { id: string; text: string; [name: string]: string },
        ) => {
          const key = val.id?.split('.')[0];
          return {
            ...acc,
            [key]: val.text,
            ...(key === 'region' ? { short_code: val.short_code } : {}),
          };
        },
        { full_address: place_name, place: place || text },
      )
    : { full_address: place_name, place: place || text };
